.app {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background-color: #18181A;
  color: #fff;
  font-family: Roboto;
}

.bgImage {
  position: absolute;
  z-index: 1;

  user-select: none;
  pointer-events: none;
}

.topLeftImage {
  composes: bgImage;
  top: 0;
  left: 0;
}

.bottomRightImage {
  composes: bgImage;
  bottom: 0;
  right: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 570px;
  max-width: 80%;
  z-index: 2;
}

.title {
  margin-bottom: 30px;

  font-size: 46px;
  text-align: center;
  word-break: break-word;

  @media (max-height: 700px) {
    font-size: 36px;
    margin-bottom: 24px;
  }
}

.description {
  margin-bottom: 45px;

  font-size: 20px;
  text-align: center;
  word-break: break-word;

  @media (max-height: 700px) {
    font-size: 16px;
    margin-bottom: 32px;
  }
}

.logo {
  position: absolute;
  bottom: 21px;
  left: 23px;
}
